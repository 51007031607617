import React, { memo } from 'react';
import cl from 'classnames';

import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { AttachmentsListAttachments } from './AttachmentsList.types';
import { UpdateFileAttachmentCacheQuery } from '../../../../fileAttachments/fileAttachmentsTypes';
import { UserNanoID } from '../../../../users/usersTypes';
import { FetchFileAttachmentsQueryResponse } from '../../../../fileAttachments/queries/fetchFileAttachments.query';

import { useTaskShowPage } from '../../../../tasks/hooks/useTaskShowPage';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import {
  Checkable,
  CheckedHashItem,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { AttachmentsListItem } from '../AttachmentsListItem';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

interface AttachmentsListProps {
  cacheKeys?: string[];
  checkable: Checkable;
  checkedHash?: CheckedHashItem;
  isGridView?: boolean;
  items: AttachmentsListAttachments;
  modalMode?: boolean;
  onCheck?: OnSetCheckedIds;
  onLightboxOpen?: OnLightboxOpenAction;
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
  userNanoId?: UserNanoID;
}

function AttachmentsList({
  cacheKeys,
  checkable,
  checkedHash,
  isGridView,
  items,
  modalMode,
  onCheck,
  onLightboxOpen,
  projectNanoId,
  taskNanoId,
  updateAttachmentCache,
  userNanoId
}: AttachmentsListProps) {
  const currentUser = useCurrentUser();

  const { task } = useTaskShowPage({
    taskNanoId,
    options: { enabled: !!taskNanoId, enabledPlaceholder: !!taskNanoId }
  });

  const withLdApi = !!currentUser?.currentTeam?.useLdApiAt;

  return (
    <div
      className={cl({
        'grid grid-cols-auto-fill grid-cell-min-56 3xl:grid-cell-min-72 gap-2 pb-4':
          isGridView
      })}
    >
      {items.map((item) => (
        <AttachmentsListItem
          taskNanoId={taskNanoId}
          userNanoId={userNanoId}
          key={item.id}
          item={item}
          items={items}
          onCheck={onCheck}
          checked={checkedHash?.[item.id]}
          checkable={checkable}
          modalMode={modalMode}
          isGridView={isGridView}
          onLightboxOpen={onLightboxOpen}
          projectNanoId={projectNanoId}
          updateAttachmentCache={updateAttachmentCache}
          cacheKeys={cacheKeys}
          taskLdSku={task?.ldSku}
          taskLdCustomerId={task?.ldCustomerId}
          withLdApi={withLdApi}
        />
      ))}
    </div>
  );
}

export default memo<AttachmentsListProps>(AttachmentsList);
