import { GADataType } from '../../../../../types';
import { TaskLdCustomerID, TaskLdSku } from '../../../../tasks/tasksTypes';

import {
  SimpleModalButton,
  SimpleModalButtonDefaultProps
} from '../../../../../helpers/buttons/SimpleModalButton';

import {
  LD_CONFIGURATOR_TOKEN,
  LD_CONFIGURATOR_URL
} from '../../../../../config';

type LdFileViewerModalButtonProps = Pick<
  SimpleModalButtonDefaultProps,
  | 'className'
  | 'i18nText'
  | 'i18nTextClassName'
  | 'i18nTitle'
  | 'title'
  | 'icon'
  | 'iconClassName'
  | 'tooltipPlacement'
  | 'tooltipI18nText'
  | 'modalSize'
> & {
  taskLdSku: TaskLdSku;
  taskLdCustomerId: TaskLdCustomerID;
};

function LdFileViewerModalButton({
  className,
  dataGa,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  modalSize,
  title,
  tooltipI18nText,
  tooltipPlacement,
  taskLdSku,
  taskLdCustomerId
}: LdFileViewerModalButtonProps & GADataType) {
  const ldConfiguratorUrl = `${LD_CONFIGURATOR_URL}/by_product_id/${taskLdCustomerId}/${taskLdSku}?token=${LD_CONFIGURATOR_TOKEN}`;

  return (
    <SimpleModalButton
      addModalClassName="h-full"
      buttonsContainerClassName="p-0"
      childrenClassName="w-full h-full relative"
      className={className}
      dataGa={dataGa}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalSize={modalSize || 'full'}
      title={title}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      withoutCancelButton
      withoutSubmitButton
    >
      <div className="w-full h-full">
        <iframe
          src={ldConfiguratorUrl}
          allow="accelerometer; autoplay; camera;encrypted-media; gyroscope; picture-in-picture;xr-spatial-tracking;xr"
          allowFullScreen
          className="w-full h-full sm:rounded-b-3xl"
        />
      </div>
    </SimpleModalButton>
  );
}

export default LdFileViewerModalButton;
