import React, { useCallback } from 'react';

import { AttachmentsListItemAttachment } from './AttachmentsListItem.types';
import { AttachmentsListAttachments } from '../AttachmentsList';
import {
  TaskLdCustomerID,
  TaskLdSku,
  TaskNanoID
} from '../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { UpdateFileAttachmentCacheQuery } from '../../../../fileAttachments/fileAttachmentsTypes';
import { UserNanoID } from '../../../../users/usersTypes';

import { FetchFileAttachmentsQueryResponse } from '../../../../fileAttachments/queries/fetchFileAttachments.query';

import {
  Checkable,
  Checked,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { AttachmentsListItemListView } from './components/AttachmentsListItemListView';
import { AttachmentsListItemGridView } from './components/AttachmentsListItemGridView';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

interface AttachmentsListItemProps {
  taskNanoId: TaskNanoID;
  projectNanoId: ProjectNanoID;
  userNanoId?: UserNanoID;
  item: AttachmentsListItemAttachment;
  items?: AttachmentsListAttachments;
  onCheck: OnSetCheckedIds;
  checked: Checked;
  checkable: Checkable;
  modalMode?: boolean;
  isGridView: boolean;
  onLightboxOpen?: OnLightboxOpenAction;
  updateAttachmentCache?: UpdateFileAttachmentCacheQuery<FetchFileAttachmentsQueryResponse>;
  cacheKeys?: string[];
  taskLdSku?: TaskLdSku;
  taskLdCustomerId?: TaskLdCustomerID;
  withLdApi?: boolean;
}

function AttachmentsListItem({
  taskNanoId,
  projectNanoId,
  userNanoId,
  item,
  items,
  onCheck,
  checked,
  checkable,
  modalMode,
  isGridView,
  onLightboxOpen,
  updateAttachmentCache,
  cacheKeys,
  taskLdSku,
  taskLdCustomerId,
  withLdApi
}: AttachmentsListItemProps) {
  const handleCheck = useCallback<() => void>(
    () => onCheck?.(item.id),
    [item, onCheck]
  );

  const withViewLdFile = !!(
    withLdApi &&
    item.ldFileAt &&
    taskLdSku &&
    taskLdCustomerId
  );

  const withLightbox = onLightboxOpen && item.href && Files.isImage(item.href);

  const handleLightboxOpen = useCallback<() => void>(
    () =>
      withLightbox &&
      onLightboxOpen?.({ file: item.href, uuid: item.fileUuid }),
    [item, withLightbox, onLightboxOpen]
  );

  return isGridView ? (
    <AttachmentsListItemGridView
      item={item}
      items={items}
      handleCheck={handleCheck}
      checkboxChecked={checked}
      checkable={checkable}
      onLightboxOpen={withLightbox ? handleLightboxOpen : undefined}
      cacheKeys={cacheKeys}
      updateAttachmentCache={updateAttachmentCache}
      taskNanoId={taskNanoId}
      userNanoId={userNanoId}
      taskLdSku={taskLdSku}
      taskLdCustomerId={taskLdCustomerId}
      withViewLdFile={withViewLdFile}
    />
  ) : (
    <AttachmentsListItemListView
      item={item}
      items={items}
      handleCheck={handleCheck}
      checkboxChecked={checked}
      checkable={checkable}
      modalMode={modalMode}
      onLightboxOpen={withLightbox ? handleLightboxOpen : undefined}
      cacheKeys={cacheKeys}
      updateAttachmentCache={updateAttachmentCache}
      taskNanoId={taskNanoId}
      projectNanoId={projectNanoId}
      taskLdCustomerId={taskLdCustomerId}
      taskLdSku={taskLdSku}
      withViewLdFile={withViewLdFile}
    />
  );
}

export default AttachmentsListItem;
